import React from "react";

import SEO from "../../components/seo";
import Aside from "../../components/aside/aside";
import AsideContent from "../../components/aside/aside-content";
import Main from "../../components/main/main";
import MainContent from "../../components/main/main-content";

import JSONData from "../../../content/website-content.en.json";

class IndexPage extends React.Component {

    render() {
        return (
            <>
                <SEO title="Home" />
                <Aside name={JSONData.aside.intro.name}
                    occupation={JSONData.aside.intro.occupation}>
                        {JSONData.aside.content.map((data, index) => {
                            return <AsideContent key={`aside_content_${index}`} title={data.title}
                                blocks={data.blocks}>
                            </AsideContent>
                        })}
                </Aside>
                <Main>
                    {JSONData.main.sections.map((data, index) => {
                        return <MainContent key={`main_content_${index}`} headline={data.headline}
                            blocks={data.blocks}>
                        </MainContent>
                    })}
                </Main>
            </>
        )
    }
};

export default IndexPage;